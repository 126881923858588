import { Link } from 'react-router-dom';
import { PAGE_SEARCH_LIMIT } from '../const';

const Breadcrumb = ( {props, corpNo, corpName, prefName, addressName, prefectureId, addressId, url, topic} ) => {

  return (
    <div className="breadcrumbs">
        {url !== undefined &&
          (
            <div>
              <p>
                <Link to={"/"}>TOP</Link>
                <span>&gt;</span>
                <Link to={"/search/" + url}>
                  {topic}
                </Link>
                <span>&gt;</span>
                <span to={"/corp/"+ corpNo + "/"}>
                {corpName} ({corpNo})
                </span>
              </p>
              <p>
                <Link to={"/"}>TOP</Link>
                <span>&gt;</span>
                <Link to={"/search/?offset=0&limit="+PAGE_SEARCH_LIMIT+"&pref=" + prefectureId + "&town=" + addressId}>
                  {prefName}{addressName}
                </Link>
                <span>&gt;</span>
                <span to={"/corp/"+ corpNo + "/"}>
                {corpName} ({corpNo})
                </span>
              </p>
            </div>
          )
        }
        {corpNo === undefined &&
          (
            <p>
              <Link to={"/"}>TOP</Link>
              <span>&gt;</span>
              <span>{props}</span>
            </p>
          )
        }
        {corpNo !== undefined && url === undefined && 
          (
            <p>
              <Link to={"/"}>TOP</Link>
              <span>&gt;</span>
              <Link to={"/search/?offset=0&limit="+PAGE_SEARCH_LIMIT+"&pref=" + prefectureId + "&town=" + addressId}>
                {prefName}{addressName}
              </Link>
              <span>&gt;</span>
              {props === "基本情報" &&
                (
                  <span>
                    {corpName} ({corpNo})
                  </span>
                )
              }
              {props !== "基本情報" &&
                (
                  <span>
                    <Link to={"/corp/"+ corpNo + "/"}>
                      {corpName} ({corpNo})
                    </Link>
                    <span>&gt;</span>
                    <span>{props}</span>
                  </span>
                )
              }
            </p>
          )
        }
    </div>
  );
}
export default Breadcrumb;
