import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import queryString from 'query-string'
import IndustrySelectBox from './parts/industry_select';
import TownSelectBoxS from './parts/town_select_search';
import CapitalSelect from './parts/capital_select';
import SearchResultList from './parts/search_result_list';
import Pagination from './parts/pagination';
import DocumentMeta from 'react-document-meta';
import axios from 'axios';
import ScriptTag from 'react-script-tag';
import AdBannerH from './parts/ad_banner_h'
import Header from './parts/header';
import AdBannerV from './parts/ad_banner_v'
import Breadcrumb from './parts/breadcrumb';
import { NO_SELECT_VALUE, PREFECTURES, PREFECTURES_OPTIONS, INDUSTRIES, PAGE_BASE_TITLE, PAGE_SEARCH_LIMIT, CAPITALS } from './const'
import EstCloseDateSelect from './parts/est_close_date_select';

const Search = ( {props} ) => {
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const [prefCd, setPrefCd] = useState(qs.pref ? qs.pref : NO_SELECT_VALUE);
  const [townCd, setTownCd] = useState(qs.town ? qs.town : NO_SELECT_VALUE);
  const [industryCd, setIndustryCd] = useState(qs.industry ? qs.industry : NO_SELECT_VALUE);
  const [capitalFromCd, setCapitalFromCd] = useState(qs.capitalFrom ? qs.capitalFrom : NO_SELECT_VALUE);
  const [capitalToCd, setCapitalToCd] = useState(qs.capitalTo ? qs.capitalTo : NO_SELECT_VALUE);
  const [count, setCount] = useState(0);
  const [countAll, setCountAll] = useState(0);
  const [offset, setOffset] = useState(qs.offset ? qs.offset : 0);
  const [limit, setLimit] = useState(qs.limit ? qs.limit : PAGE_SEARCH_LIMIT);
  const [corpWord, setCorpWord] = useState(qs.corpWord ? qs.corpWord : "")
  const [searchResultList, setSearchResultList] = useState([])
  const [includeStopCorp, setIncludeStopCorp] = useState(qs.corpCloseFlg ? true : false);
  const [diableIncludeStopCorp, setDiableIncludeStopCorp] = useState(false);
  const [recentlyStartupMode, setRecentlyStartupMode] = useState(qs.recentlyStartupMode ? true : false);
  const [recentlyCloseMode, setRecentlyCloseMode] = useState(qs.recentlyCloseMode ? true : false);
  const [invoiceFlg, setInvoiceFlg] = useState(qs.invoiceFlg ? true : false);
  const [topic1, setTopic1] = useState("");
  const [loading, setLoading] = useState(true);
  const [notfound, setNotFound] = useState(false);

  // 設立・倒産企業の検索の検索条件
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30); // NOTE: デフォルトは30日前
  const [year, setYear] = useState(
    qs.from_year ? qs.from_year : defaultDate.getFullYear()
  );
  const [month, setMonth] = useState(
    qs.from_month ? qs.from_month : defaultDate.getMonth() + 1
  );
  const [day, setDay] = useState(
    qs.from_day ? qs.from_day : defaultDate.getDate()
  );

  useEffect(() => { 
    (async() => {
      const URL = searchApiDecorate(offset, limit);
      let topic = await getTopic();
      const fetch = async () => {
        try {
          const response = await axios.get(URL);
          const arrayPost = response.data;
          arrayPost.payload.forEach((value, index) => {
            arrayPost.payload[index]["url"] = location.search;
            arrayPost.payload[index]["topic"] = topic;
          });
          setSearchResultList(arrayPost.payload);
          setLimit(Number(arrayPost.limit));
          setCount(Number(arrayPost.count));
          setCountAll(Number(arrayPost.countAll));
          setOffset(Number(arrayPost.offset));
        } catch (error) {
          setNotFound(true);
        }
        setLoading(false);
      }
      fetch();
    })()
  }, [props]);

  function getPrefName(prefCode) {
    if (PREFECTURES[prefCode]) {
      return PREFECTURES[prefCode];
    }
    return '';
  }

  function getIndustryName(industryCode) {
    if (INDUSTRIES[industryCode]) {
      return INDUSTRIES[industryCode];
    }
    return '';
  }

  async function getTownName(prefCd, townCd) {
    const URL = "https://api.company-search.jp/town/?q=" + prefCd;
    let name = "";
    try {
      if (prefCd !== '0') {
        const response = await axios.get(URL);
        const findTown = response.data.find((town) => {return townCd === String(town.code)});
        return findTown.name;
      }
    } catch (error) {
      console.error(error);
    }
    return name;
  }

  function savePrefCd(event) {
    setPrefCd(event.target.value);
    setTownCd('0');
  };

  function saveTownCd(event) {
    setTownCd(event.target.value);
  };

  function saveCapitalFromCd(event){
    setCapitalFromCd(event.target.value)
  }

  function saveCapitalToCd(event){
    setCapitalToCd(event.target.value)
  }

  function saveIndustryCd(event) {
    setIndustryCd(event.target.value);
  };

  function saveCorpWord(event) {
    setCorpWord(event.target.value);
  };

  function saveIncludeStopCorp(event) {
    setIncludeStopCorp(event.target.checked);
  };

  const toggleInvoiceFlg = () => {
    setInvoiceFlg(!invoiceFlg);
  }

  /**
   * 「設立・倒産企業」の検索条件の設定状況によって「倒産・廃業した企業を含める」の条件を設定する
   * @param {boolean} isCloseSelected 倒産企業を検索する場合、true
   */
  const enableStopCorp = (isCloseSelected) => {
    if (isCloseSelected) {
      // 「倒産・廃業が」ボタンを設定されている場合はチェックボックスをチェック済みとし、非活性とする。
      setDiableIncludeStopCorp(true);
      setIncludeStopCorp(true);
    } else {
      setDiableIncludeStopCorp(false);
    }
  }

  function searchApiDecorate(lOffset, lLimit){
    let URL = "https://api.company-search.jp/corp/search/"
      + "?offset=" + lOffset;

    if (lLimit === PAGE_SEARCH_LIMIT) {
      URL += "&limit=" + lLimit;
    } else {
      URL += "&limit=" + PAGE_SEARCH_LIMIT;
    }
    if (prefCd !== NO_SELECT_VALUE) {
      URL += "&perf=" + String(prefCd);
    }
    if (townCd !== NO_SELECT_VALUE) {
      URL += "&town=" + String(townCd);
    }
    if (industryCd !== NO_SELECT_VALUE) {
      URL += "&industry=" + String(industryCd);
    }
    if (capitalFromCd !== NO_SELECT_VALUE) {
      URL += "&capitalFrom=" + String(capitalFromCd);
    }
    if (capitalToCd !== NO_SELECT_VALUE) {
      URL += "&capitalTo=" + String(capitalToCd);
    }
    if (corpWord) {
      URL += "&corpWord=" + encodeURIComponent(String(corpWord));
    }
    if (includeStopCorp) {
      URL += "&corpCloseFlg=1";
    }
    if (recentlyStartupMode) {
      URL += "&recentlyStartupMode=1";
    }
    if (recentlyCloseMode) {
      URL += "&recentlyCloseMode=1";
    }
    if (recentlyStartupMode || recentlyCloseMode) {
      URL += `&from_year=${year}&from_month=${month}&from_day=${day}`;
    }
    if (invoiceFlg) {
      URL += "&invoiceFlg=1";
    }
    return URL;
  }

  function searchPageDecorate(lOffset, lLimit){
    let URL = "/search/"
      + "?offset=" + lOffset 
      + "&limit=" + lLimit;

    if (prefCd !== NO_SELECT_VALUE) {
      URL += "&pref=" + String(prefCd);
    }
    if (townCd !== NO_SELECT_VALUE) {
      URL += "&town=" + String(townCd);
    }
    if (industryCd !== NO_SELECT_VALUE) {
      URL += "&industry=" + String(industryCd);
    }
    if (capitalFromCd !== NO_SELECT_VALUE){
      URL += "&capitalFrom=" + String(capitalFromCd);
    }
    if (capitalToCd !== NO_SELECT_VALUE){
      URL += "&capitalTo=" + String(capitalToCd);
    }
    if (corpWord) {
      URL += "&corpWord=" + encodeURIComponent(String(corpWord));
    }
    if (includeStopCorp) {
      URL += "&corpCloseFlg=1";
    }
    if (recentlyStartupMode) {
      URL += "&recentlyStartupMode=1";
    }
    if (recentlyCloseMode) {
      URL += "&recentlyCloseMode=1";
    }
    if (recentlyStartupMode || recentlyCloseMode) {
      URL += `&from_year=${year}&from_month=${month}&from_day=${day}`;
    }
    if (invoiceFlg) {
      URL += "&invoiceFlg=1";
    }
    return URL;
  }

  async function handleSearch(event) {
    const lOffset = 0;
    const lLimit = PAGE_SEARCH_LIMIT;
    const URL = searchPageDecorate(lOffset, lLimit);
    window.location.href = URL
  }

  async function handlePrevNextButton(event) {
    const lOffset = event.target.getAttribute('data-offset');
    const lLimit = event.target.getAttribute('data-limit');
    const URL = searchPageDecorate(lOffset, lLimit);
    window.location.href = URL
  }

  /** 指定された検索条件の画面表示文字列を作成 */
  async function getTopic() {
    /** 検索条件全体の画面表示用文字列 */
    const topicsRowsBuilder = [];

    // 検索条件の都道府県/市区町村名を結合する
    const location = await generateLocationTopic(prefCd, townCd, getPrefName, getTownName);
    if (location) {
      topicsRowsBuilder.push(location);
    }
    // 営業品目
    if (industryCd !== NO_SELECT_VALUE) {
      topicsRowsBuilder.push(getIndustryName(industryCd));
    }
    // 資本金
    const capitalTopic = generateCapitalTopic(capitalFromCd, capitalToCd);
    if (capitalTopic) {
      topicsRowsBuilder.push(capitalTopic);
    }
    // 企業名・法人番号
    if (corpWord !== "") {
      topicsRowsBuilder.push(corpWord);
    }
    // 設立・倒産企業
    if (recentlyStartupMode) {
      topicsRowsBuilder.push(`設立が${year}年${month}月${day}日以降`);
    } else if (recentlyCloseMode) {
      topicsRowsBuilder.push(`倒産・廃業が${year}年${month}月${day}日以降`);
    }
    // 倒産した企業も含む
    if (includeStopCorp) {
      topicsRowsBuilder.push("倒産した企業も含む");
    }
    // 適格請求書（インボイス）発行事業者登録番号あり
    if (invoiceFlg) {
      topicsRowsBuilder.push("適格請求書（インボイス）発行事業者登録番号あり");
    }

    // 検索条件未指定の場合
    if (topicsRowsBuilder.length === 0) {
      const topicText = "企業一覧";
      setTopic1(topicText);
      document.title = `${topicText} | ${PAGE_BASE_TITLE}`;
      return topicText;
    }

    const topicText = "「" +topicsRowsBuilder.join(',') + "」の企業一覧";
    setTopic1(topicText);
    document.title = `${topicText} | ${PAGE_BASE_TITLE}`;
    return topicText;
  }

  var desc = "";
  var title = "";
  if (topic1 === "企業一覧") {
    title = "企業一覧 - 企業情報・法人情報検索のCompany Search";
    desc = "全国約500万件の企業一覧です。Company Searchは企業名（フリーワード）、法人番号、都道府県や市区町村、業種、最近設立・倒産した企業など様々な条件を使って企業情報・法人情報の検索を行うことが出来ます。 - 企業情報・法人情報検索のCompany Search";
  } else {
    let condStr = topic1
    title = condStr + "- 企業情報・法人情報検索のCompany Search";
    desc = "全国約500万件のリストから検索した";
    desc += condStr;
    desc += "です。Company Searchは企業名（フリーワード）、法人番号、都道府県や市区町村、業種、最近設立・倒産した企業など様々な条件を使って企業情報・法人情報の検索を行うことが出来ます。 - 企業情報・法人情報検索のCompany Search";
  } 
  const meta = {
    title: title,
    description: desc,
    robots: "all",
    meta: {
      property: {
        'og:url': 'https://company-search.jp'+ searchPageDecorate(offset, limit),
        'og:type': 'website',
        'og:title': title,
        'og:description': desc,
        'og:site_name': 'Company Search - 企業情報・法人情報検索',
        'og:image': 'https://company-search.jp/logo.png',
      }
    }
  };

  return (
    <div>
      <DocumentMeta {...meta}>
      <Header 
        pPrefCd={qs.pref}
        pTownCd={qs.town}
        pIndustryCd={qs.industry}
        pCapitalFromCd={qs.capitalFrom}
        pCapitalToCd={qs.capitalTo}
        pCorpWord={qs.corpWord}
        pIncludeStopCorp={qs.corpCloseFlg ? '1' : ''}
        pRecentlyStartupMode={qs.recentlyStartupMode ? '1' : ''}
        pRecentlyCloseMode={qs.recentlyCloseMode ? '1' : ''}
        pInvoiceFlg={qs.invoiceFlg ? '1' : ''}
        pFromYear={qs.from_year}
        pFromMonth={qs.from_month}
        pFromDay={qs.from_day}
      />
      <div className="wrapper search_result all_corp">
        {loading && (
          <ScriptTag type="text/javascript" src="/jquery_js/loading_s.js" />
        )}
        {!loading && (
          <ScriptTag type="text/javascript" src="/jquery_js/loading_e.js" />
        )}
        <div className="loading-wrapper">
          <div className="loading-inner">
            <div className="loading-cont">
              <div className="loader_wrap"><div className="loader_inner"><div className="loader"></div></div></div>
              <p className="sh_txt">
                <span className="span_txt">Search&nbsp;Now<span className="dot_text"><span className="sss">.</span><span>.</span><span>.</span></span></span>
              </p>
            </div>
          </div>
          <span className="loading-back"></span>
        </div>
        {topic1 === '企業一覧' && (
          <Breadcrumb props={"企業一覧"}/>
        )}
        {topic1 !== '企業一覧' && (
          <Breadcrumb props={topic1}/>
        )}
        <div className="content_wrap">
          <main className="main_wrap">
            <div className="content_inner">
              <div className="ttl">
                <h1>
                  <span>{topic1}</span>
                </h1>
              </div>
              <div className="adv">
                <AdBannerH />
              </div>
              <Pagination up={true} count={count} countAll={countAll} offset={offset} limit={limit}  handlePrevNext={handlePrevNextButton}/>
              <div style={{height: '100%', display: notfound ? 'block' : 'none'}}>
                <h3>検索結果が存在しませんでした。</h3>
                <br/>
                <small>別の条件で検索してください。</small>
              </div>
              <SearchResultList props={searchResultList} />
              <Pagination up={false} count={count} countAll={countAll} offset={offset} limit={limit}  handlePrevNext={handlePrevNextButton}/>
              <div className="adv">
                <AdBannerH />
              </div>
            </div>
          </main>
          <aside className="sidenav pc">
            <div className="search_box">
              <div className="search_box_inner">
                <h2>検索条件</h2>
                <ul>
                  <li>
                    <p>都道府県</p>
                    <div className="select_wrap">
                      <select className="pre_select" id="pre_select_s" name="" value={prefCd} onChange={savePrefCd}>
                        <option value="0">全ての都道府県</option>
                        <PREFECTURES_OPTIONS />
                      </select>
                    </div>
                  </li>
                  <li>
                    <p>市区町村</p>
                    <TownSelectBoxS saveTownCd={saveTownCd} prefCd={prefCd} townCd={townCd}/>
                  </li>
                  <li>
                    <p>営業品目</p>
                    <IndustrySelectBox saveIndustryCd={saveIndustryCd} industryCd={industryCd}/>
                  </li>
                  <li>
                    <p>資本金</p>
                    <CapitalSelect saveCapitalFromCd={saveCapitalFromCd} capitalFromCd={capitalFromCd}
                                    saveCapitalToCd={saveCapitalToCd} capitalToCd={capitalToCd}></CapitalSelect>
                  </li>
                  <li>
                    <p>企業名・法人番号（13桁）</p>
                    <input type="text" name="" id="" value={corpWord} onChange={saveCorpWord} />
                  </li>

                  <li>
                    <EstCloseDateSelect
                      recentlyStartupMode = {recentlyStartupMode}
                      recentlyCloseMode = {recentlyCloseMode}
                      year={year}
                      month={month}
                      day={day}
                      setYear={setYear}
                      setMonth={setMonth}
                      setDay={setDay}
                      setRecentlyStartupMode={setRecentlyStartupMode}
                      setRecentlyCloseMode={setRecentlyCloseMode}
                      onModeChange={enableStopCorp}
                      />
                  </li>
                  <li className="side_check_box">
                    <input type="checkbox" id="A3" name="A3" checked={includeStopCorp} onChange={saveIncludeStopCorp} disabled={diableIncludeStopCorp} />
                    <label htmlFor="A3"><p>倒産した企業を含める</p></label>
                  </li>
                  <li className="side_check_box">
                    <input
                      type="checkbox"
                      id="invoice"
                      name="invoice"
                      defaultChecked={invoiceFlg}
                      onChange={toggleInvoiceFlg}
                    />
                    <label htmlFor="invoice"><p>適格請求書（インボイス）<span className="inblock">発行事業者登録番号あり</span></p></label>
                  </li>
                </ul>
                <button className="search_btn" onClick={handleSearch}>検索</button>
                <br/>
                <br/>
                <AdBannerV />
              </div>
            </div>
          </aside>
        </div>
      </div>
      </DocumentMeta>
    </div>
  );
}

/**
 * 都道府県/市区町村用検索条件文字列を作成
 * 両方とも指定されていない場合nullを返す
 */
async function generateLocationTopic(prefCd, townCd, getPrefName, getTownName) {
  const builder = [];
  if (prefCd !== NO_SELECT_VALUE) {
    builder.push(getPrefName(prefCd));
  }
  if (townCd !== NO_SELECT_VALUE) {
    builder.push(await getTownName(prefCd, townCd));
  }
  return builder.join("");
}

/**
 * 資本金用検索条件文字列を作成
 * 両方とも指定されていない場合nullを返す
 */
function generateCapitalTopic(capitalFromCd, capitalToCd) {

  const from = CAPITALS[capitalFromCd];
  const to = CAPITALS[capitalToCd];

  if (from && to) return `資本金${from}〜${to}`;
  if (from) return `資本金${from}以上`;
  if (to) return `資本金${to}以下`;
  return null;
}

export default Search;
