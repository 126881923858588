import { useParams, Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import map_icon from './img/map_icon.svg';
import type_icon from './img/type_icon.svg';
import { DATA_AVAILABLE, PAGE_BASE_TITLE, PAGE_SEARCH_LIMIT } from './const';
import CorporationBasic from './corporation_parts/corporation_basic'
import CorporationCert from './corporation_parts/corporation_cert'
import CorporationCommendation from './corporation_parts/corporation_commendation'
import CorporationSubsidy from './corporation_parts/corporation_subsidy'
import CorporationProducement from './corporation_parts/corporation_procurement'
import CorporationWorkplace from './corporation_parts/corporation_workplace'
import SameIndustryCorp from './parts/same_industry_corp'
import AdBannerV from './parts/ad_banner_v'
import NotFound from './not_found'
import Breadcrumb from './parts/breadcrumb';

const CorporationBase = ( props ) => {
  let location = useLocation();
  let {corpNo} = useParams();
  const [mode, setMode] = useState(props.mode);
  const [corpSummary, setCorpSummary] = useState({
    dataFlg: {
      basic:0, cert:0, commendation:0, subsidy:0, producement:0, financial:0, workplace:0, blog:0
    }
  });
  const [isNone, setIsNone] = useState(false);
  document.title = '企業個別ページ | ' + PAGE_BASE_TITLE;
  useEffect(() => {
    setMode(props.mode);
    const fetch = async () => {
      const URL = "https://api.company-search.jp/corp/" + corpNo +"/";
      try {
        const response = await axios.get(URL);
        setCorpSummary(response.data);
      } catch (error) {
        if (error.response.status === 404) {
          setIsNone(true);
        }
        console.error(error);
      }
    }
    fetch();
  }, [props.mode, corpNo]);

  if (isNone) {
    return (
      <NotFound/>
    );
  }

  return (
    <div className="wrapper corporate">
      {mode === "basic" &&
        (
          <Breadcrumb props={"基本情報"} corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} prefectureId={corpSummary.prefectureId} addressId={corpSummary.addressId} url={location.url} topic={location.topic}/>
        )
      }
      {mode === "cert" &&
        (
          <Breadcrumb props={"届出認定情報"} corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} prefectureId={corpSummary.prefectureId} addressId={corpSummary.addressId}/>
        )
      }
      {mode === "commendation" &&
        (
          <Breadcrumb props={"表彰情報"} corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} prefectureId={corpSummary.prefectureId} addressId={corpSummary.addressId}/>
        )
      }
      {mode === "subsidy" &&
        (
          <Breadcrumb props={"補助金情報"} corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} prefectureId={corpSummary.prefectureId} addressId={corpSummary.addressId}/>
        )
      }
      {mode === "procurement" &&
        (
          <Breadcrumb props={"調達情報"} corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} prefectureId={corpSummary.prefectureId} addressId={corpSummary.addressId}/>
        )
      }
      {mode === "workplace" &&
        (
          <Breadcrumb props={"職場情報"} corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} prefectureId={corpSummary.prefectureId} addressId={corpSummary.addressId}/>
        )
      }
      <section className="under_mv">
        <div className="biginner">
          <div className="dfb corp_state">
            <p className="corp_num">法人番号：{corpNo}</p>
            {corpSummary.corporateCloseDate　&&
            <p className="close_corp">閉鎖：{corpSummary.corporateCloseDate}</p>
            }
          </div>
          <div className="dfb ttl">
            <h1>{corpSummary.corpName}</h1>
            <button className="fav_button" style={{display:'none'}}>
              <span className="pc txt">お気に入り追加</span>
            </button>
          </div>
          <div className="icon_wrap">
            <p className="add">
              <img src={map_icon} alt="map" />
              {corpSummary.zipcode ? '〒' + corpSummary.zipcode : ''}
              &nbsp;
              {corpSummary.corporateAddress ? corpSummary.corporateAddress : '--------'}
            </p>
            <p className="indust" style={{lineHeight:'1.5em'}}>
              <img src={type_icon} alt="type" />
              {corpSummary.industry ? corpSummary.industry.join(" / ") : '--------'}
            </p>
          </div>
          <div className="info">
            <dl className="dfb">
              <dt>設立</dt>
              <dd>{corpSummary.foundationDate ? corpSummary.foundationDate : '--------'}</dd>
            </dl>
            <dl className="dfb">
              <dt>代表</dt>
              <dd>{corpSummary.representativeName ? corpSummary.representativeName : '--------'}</dd>
            </dl>
            <dl className="dfb">
              <dt>事業内容</dt>
              <dd>{corpSummary.overview ? corpSummary.overview : '--------'}</dd>
            </dl>
          </div>
        </div>
      </section>
      <div className="tab_biginner">
        <div className="tab-area">
          <div className="btn_wrap">
            <button className="left"></button>
            <button className="right"></button>	
          </div>
          <div className="tab_wrap">
            <ul className="tab_inner">
              {corpSummary.dataFlg.basic === DATA_AVAILABLE &&
                (
                  <li className={mode === 'basic' ? 'tab active' : 'tab'}>
                      {mode !== 'basic' && <Link to={"/corp/"+ corpNo + "/"}>基本情報</Link>}
                      {mode === 'basic' && <p style={{font_color: 'white'}}>基本情報</p>}
                  </li>
                )
              }
              {corpSummary.dataFlg.cert === DATA_AVAILABLE &&
                (
                  <li className={mode === 'cert' ? 'tab active' : 'tab'}>
                      {mode !== 'cert' && <Link to={"/corp/"+ corpNo + "/cert/"}>届出認定情報</Link>}
                      {mode === 'cert' && <p style={{font_color: 'white'}}>届出認定情報</p>}
                  </li>
                )
              }
              {corpSummary.dataFlg.commendation === DATA_AVAILABLE &&
                (
                  <li className={mode === 'commendation' ? 'tab active' : 'tab'}>
                      {mode !== 'commendation' && <Link to={"/corp/"+ corpNo + "/commendation/"}>表彰情報</Link>}
                      {mode === 'commendation' && <p style={{font_color: 'white'}}>表彰情報</p>}
                  </li>
                )
              }
              {corpSummary.dataFlg.subsidy === DATA_AVAILABLE &&
                (
                  <li className={mode === 'subsidy' ? 'tab active' : 'tab'}>
                      {mode !== 'subsidy' && <Link to={"/corp/"+ corpNo + "/subsidy/"}>補助金情報</Link>}
                      {mode === 'subsidy' && <p style={{font_color: 'white'}}>補助金情報</p>}
                  </li>
                )
              }
              {corpSummary.dataFlg.procurement === DATA_AVAILABLE &&
                (
                  <li className={mode === 'procurement' ? 'tab active' : 'tab'}>
                      {mode !== 'procurement' && <Link to={"/corp/"+ corpNo + "/procurement/"}>調達情報</Link>}
                      {mode === 'procurement' && <p style={{font_color: 'white'}}>調達情報</p>}
                  </li>
                )
              }
              {corpSummary.dataFlg.workplace === DATA_AVAILABLE &&
                (
                  <li className={mode === 'workplace' ? 'tab active' : 'tab'}>
                      {mode !== 'workplace' && <Link to={"/corp/"+ corpNo + "/workplace/"}>職場情報</Link>}
                      {mode === 'workplace' && <p style={{font_color: 'white'}}>職場情報</p>}
                  </li>
                )
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="content_wrap">
        <main className="main_wrap">
          <div className="content-area">
          { mode === 'basic' && <CorporationBasic corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} /> }
          { mode === 'cert' && <CorporationCert corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} /> }
          { mode === 'commendation' && <CorporationCommendation corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} /> }
          { mode === 'subsidy' && <CorporationSubsidy corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} /> }
          { mode === 'procurement' && <CorporationProducement corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} /> }
          { mode === 'workplace' && <CorporationWorkplace corpNo={corpNo} corpName={corpSummary.corpName} prefName={corpSummary.prefectureName} addressName={corpSummary.addressName} /> }
          </div>
        </main>
        <aside class="sidenav">
          <SameIndustryCorp corpNo={corpNo} />
          <div class="sidenav_inner">
            <AdBannerV />
          </div>
        </aside>
      </div>
    </div>
  );
}
 
export default CorporationBase;
