import map_icon from '../img/map_icon.svg';
import { PAGE_SEARCH_LIMIT } from '../const';

const PrefLinkList = () => {
  return (
    <section className="top_search pre_search">
    <div className="inner">
      <h2><img src={map_icon} alt="MapIcon" />都道府県から探す<span className="sp_ib"></span></h2>
      <div className="ul_wrap pre_ul"><ul className="dfb">
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=1"}>北海道</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=2"}>青森県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=3"}>岩手県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=4"}>宮城県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=5"}>秋田県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=6"}>山形県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=7"}>福島県</a></li>
        </ul>
        <ul className="dfb">
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=8"}>茨城県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=9"}>栃木県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=10"}>群馬県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=11"}>埼玉県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=12"}>千葉県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=13"}>東京都</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=14"}>神奈川県</a></li>
        </ul>
        <ul className="dfb">
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=15"}>新潟県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=16"}>富山県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=17"}>石川県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=18"}>福井県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=19"}>山梨県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=20"}>長野県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=21"}>岐阜県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=22"}>静岡県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=23"}>愛知県</a></li>
        </ul>
        <ul className="dfb">
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=24"}>三重県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=25"}>滋賀県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=26"}>京都府</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=27"}>大阪府</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=28"}>兵庫県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=29"}>奈良県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=30"}>和歌山県</a></li>
        </ul>
        <ul className="dfb">
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=31"}>鳥取県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=32"}>島根県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=33"}>岡山県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=34"}>広島県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=35"}>山口県</a></li>
        </ul>
        <ul className="dfb">
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=36"}>徳島県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=37"}>香川県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=38"}>愛媛県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=39"}>高知県</a></li>
        </ul>
        <ul className="dfb">
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=40"}>福岡県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=41"}>佐賀県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=42"}>長崎県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=43"}>熊本県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=44"}>大分県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=45"}>宮崎県</a></li>
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=46"}>鹿児島県</a></li>
        </ul>
        <ul className="dfb">
          <li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&pref=47"}>沖縄県</a></li>
        </ul>	
      </div>
    </div>
  </section>
  );
};

export default PrefLinkList;
