import { useState, useEffect } from 'react';
import axios from 'axios';
import { DEV_BANNER_URL } from '../const';

const AdBannerH = () => {
  const [ad, setAd] = useState({});
  useEffect(() => { 
    (async() => {
      const adList = await getBannerH();
      var num = Math.floor( Math.random() * adList.length );
      setAd(adList[num]);
    })()
  
    async function getBannerH() {
      const URL = DEV_BANNER_URL + "/banner_H.json";
      let BannerH = "";
      try {
        const response = await axios.get(URL);
        BannerH = response.data;
      } catch (error) {
        console.error(error);
      }
      return BannerH;
    }
  }, []);


  return (
    <p>
      <a href={ad.link} target="_blank">
        <img src={"/banner/img/"+ ad.img} alt="" />
      </a>
    </p>
  );
}

export default AdBannerH;
