const DATA_AVAILABLE = 1;

const NO_SELECT_VALUE = "0";

const PAGE_BASE_TITLE = "Company Search";

const PAGE_SEARCH_LIMIT = 10;

const DEV_BANNER_URL = "https://company-search.jp/banner";

const PREFECTURES = {
  "1":"北海道",
  "2":"青森県",
  "3":"岩手県",
  "4":"宮城県",
  "5":"秋田県",
  "6":"山形県",
  "7":"福島県",
  "8":"茨城県",
  "9":"栃木県",
  "10":"群馬県",
  "11":"埼玉県",
  "12":"千葉県",
  "13":"東京都",
  "14":"神奈川県",
  "15":"新潟県",
  "16":"富山県",
  "17":"石川県",
  "18":"福井県",
  "19":"山梨県",
  "20":"長野県",
  "21":"岐阜県",
  "22":"静岡県",
  "23":"愛知県",
  "24":"三重県",
  "25":"滋賀県",
  "26":"京都府",
  "27":"大阪府",
  "28":"兵庫県",
  "29":"奈良県",
  "30":"和歌山県",
  "31":"鳥取県",
  "32":"島根県",
  "33":"岡山県",
  "34":"広島県",
  "35":"山口県",
  "36":"徳島県",
  "37":"香川県",
  "38":"愛媛県",
  "39":"高知県",
  "40":"福岡県",
  "41":"佐賀県",
  "42":"長崎県",
  "43":"熊本県",
  "44":"大分県",
  "45":"宮崎県",
  "46":"鹿児島県",
  "47":"沖縄県",
  "99":"海外"
}

const PREFECTURES_OPTIONS = () => {
  const optionList = [];
  
  for (let key in PREFECTURES) {
    optionList.push(<option value={key} key={key}>{PREFECTURES[key]}</option>);
  }
  return optionList;
};

const INDUSTRIES = {
  "101-201" : "衣服・その他繊維製品類",
  "102-202" : "ゴム・皮革・ﾌﾟﾗｽﾁｯｸ製品",
  "103-203" : "窯業・土石製品類",
  "104-204" : "非鉄金属・金属製品類",
  "105-205" : "フォーム印刷",
  "106-206" : "その他印刷",
  "107-207" : "図書類",
  "108-208" : "電子出版物",
  "109-209" : "紙・紙加工品類",
  "110-210" : "車両類",
  "111-211" : "その他輸送・搬送機械器具類",
  "112-212" : "船舶類",
  "113-213" : "燃料類",
  "114-214" : "家具・什器類",
  "115-215" : "一般・産業用機器類",
  "116-216" : "電気・通信用機器類",
  "117-217" : "電子計算機",
  "118-218" : "精密機器類",
  "119-219" : "医療用機器",
  "120-220" : "事務用機器",
  "121-221" : "その他機器",
  "122-222" : "医薬品・医療用品類",
  "123-223" : "事務用品類",
  "124-224" : "土木・建設・建築材料",
  "127-227" : "警察用装備品類",
  "128-228" : "防衛用装備品類",
  "129-229-315-402" : "その他",
  "225" : "造幣・印刷事業用原材料類",
  "226" : "造幣事業用金属工芸品",
  "301" : "広告・宣伝",
  "302" : "写真・製図",
  "303" : "調査・研究",
  "304" : "情報処理",
  "305" : "翻訳・通訳・速記",
  "306" : "ソフトウェア開発",
  "307" : "会場等の借り上げ",
  "308" : "賃貸借",
  "309" : "建物管理等各種保守管",
  "310" : "運送",
  "311" : "車両整備",
  "312" : "船舶整備",
  "313" : "電子出版",
  "314" : "防衛用装備品類の整備",
  "401" : "立木竹",
}

const INDUSTRIES_LINK_LIST = () => {
  const linkList = [];
  
  for (let key in INDUSTRIES) {
    linkList.push(<li><a href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&industry=" + key}>{INDUSTRIES[key]}</a></li>);
  }
  return linkList;
};

const INDUSTRIES_OPTIONS = () => {
  const optionList = [];
  for (let key in INDUSTRIES) {
    optionList.push(<option value={key} key={key}>{INDUSTRIES[key]}</option>);
  }
  return optionList;
};

const CORP_CLOSE_REASON = {
  "01" : "清算の結了等",
  "11" : "合併による解散等",
  "21" : "登記官による閉鎖",
  "31" : "その他の清算の結了等",
};

const CAPITALS = {
  "00000000": "0円",
  "100000000": "1億円",
  "500000000": "5億円",
  "1000000000": "10億円",
  "2000000000": "20億円",
  "5000000000": "50億円",
  "10000000000": "100億円",
  "100000000000": "1000億円"
};

const CAPITALS_OPTIONS = ({capitalCd, fromTo}) => {
  const optionList = []
  const keys = Object.keys(CAPITALS).sort((a,b)=>{return a-b});
  if (fromTo === "from"){
    keys.forEach(element => {
      if (Number(element) < Number(capitalCd) || Number(capitalCd) === 0){
        optionList.push(<option value={element} key={element}>{CAPITALS[element]}</option>);
      }
    });
  }
  if (fromTo === "to"){
    keys.forEach(element => {
      if (Number(element) > Number(capitalCd)){
        optionList.push(<option value={element} key={element}>{CAPITALS[element]}</option>);
      }
    });
  }
  return optionList;
}

export { DATA_AVAILABLE
  ,NO_SELECT_VALUE
  ,PREFECTURES
  ,PREFECTURES_OPTIONS
  ,INDUSTRIES
  ,INDUSTRIES_LINK_LIST
  ,INDUSTRIES_OPTIONS
  ,CORP_CLOSE_REASON
  ,PAGE_BASE_TITLE
  ,PAGE_SEARCH_LIMIT
  ,DEV_BANNER_URL
  ,CAPITALS
  ,CAPITALS_OPTIONS
};
