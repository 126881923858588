import { useState, useEffect } from 'react';
import axios from 'axios';
import map_icon from '../img/map_icon.svg';
import {CORP_CLOSE_REASON} from '../const';
import AdBannerH from '../parts/ad_banner_h';
import DocumentMeta from 'react-document-meta';

const CorporationBasic = ( {corpNo, corpName, prefName, addressName} ) => {
  const [corpBasic, setCorpBasic] = useState({});
  useEffect(() => { 
    const URL = "https://api.company-search.jp/corp/" + corpNo + "/basic/";
    const fetch = async () => {
      try {
        const response = await axios.get(URL);
        const post = response.data;
        setCorpBasic(post);
      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, [corpNo]);

  let industries;
  
  if (corpBasic.industry) {
    let indList =  [];
    corpBasic.industry.map((ind) =>{
      indList.push(<p>{ind}</p>)
    });
    industries = (
    <dd>
      {indList}
    </dd>);
  } else {
    industries = (
    <dd>
      --------
    </dd>);
  }

  let gmap;
  if (corpBasic.corporateAddress && corpBasic.zipcode) {
    let zipAndAddress = encodeURIComponent('〒' + corpBasic.zipcode + corpBasic.corporateAddress);
    gmap = (
      <a className="gmap" href={"https://www.google.co.jp/maps/place/" + zipAndAddress } target="_blank">
        <img src={map_icon} alt="" />
        Googleマップで表示
      </a>)
  } else {
    gmap = (
      <p>&nbsp;</p>
    )
  }

  let employeeNumber;
  if (corpBasic.employeeNumberFemale || corpBasic.employeeNumberMale) {
    let maleNumber = corpBasic.employeeNumberMale ? corpBasic.employeeNumberMale : 0;
    let femaleNumber = corpBasic.employeeNumberFemale ? corpBasic.employeeNumberFemale : 0;
    let totalNumber = maleNumber + femaleNumber;

    employeeNumber = (
      <dd>
        {employeeNumberDecorate(totalNumber)}人&nbsp;({employeeNumberDecorate(maleNumber)}人 / {employeeNumberDecorate(femaleNumber)}人)
      </dd>);
  } else {
    employeeNumber = (
      <dd>
        --------
      </dd>);
  }

  
  function getCloseReaasonCaption(closeReasonCode) {
    if (CORP_CLOSE_REASON[closeReasonCode]) {
      return CORP_CLOSE_REASON[closeReasonCode];
    }
    return '';
  }
  
  function employeeNumberDecorate(number) {
    if(number >= 10000) {
      return String(Math.floor(number / 10000)) + '万' + String(number % 10000) 
    }
    return number;
  }

  const noConnector = prefName ||  addressName ? 'の' : '';
  var title = corpName + "（" + corpNo + "）企業基本情報 - 企業情報・法人情報検索のCompany Search";
  var desc = prefName + addressName + noConnector + corpName + "（" + corpNo + "）企業基本情報です。" + corpName + "の住所やホームページURL、適格請求書（インボイス）発行事業者登録番号などの企業情報を掲載しています。Company Searchは全国約500万件の企業から様々な条件で企業を検索することが出来ます。 - 企業情報・法人情報検索のCompany Search";
  const meta = {
    title: title,
    description: desc,
    robots: "all",
    meta: {
      property: {
        'og:url': 'https://company-search.jp/corp/'+ corpNo + '/',
        'og:type': 'website',
        'og:title': title,
        'og:description': desc,
        'og:site_name': 'Company Search - 企業情報・法人情報検索',
        'og:image': 'https://company-search.jp/logo.png',
      }
    }
  };

  return (
    <div className="content base_info show">
      <DocumentMeta {...meta}>
      <h2 className="ttl_style3">基本情報</h2>
      <div className="adv">
        <AdBannerH />
      </div>
      {/* <div className="content_inner border_bottom">
        <div className="slide_movie dfb">
          <div className="slide">
            <div className="main_slide">
              <img src={slide} alt="slide" />
            </div>
            <ul className="thumnail dfb">
              <li><img src={thum1} alt=""/></li>
              <li><img src={thum2} alt=""/></li>
              <li><img src={thum_dummy} alt=""/></li>
              <li><img src={thum_dummy} alt=""/></li>
              <li><img src={thum_dummy} alt=""/></li>
              <li><img src={thum_dummy} alt=""/></li>
            </ul>
          </div>
          <div className="movie">
            <iframe src="https://www.youtube.com/embed/1zr5yAh0KyE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div> */}
      <div className="content_inner no_pb">
        <ul className="info_table_layout">
          <li className="base_list">
            <dl className="dpt">
              <dt>法人番号</dt>
              <dd>{corpNo}</dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>適格請求書（インボイス）発行事業者登録番号</dt>
              <dd>{corpBasic.invoiceNumber ? corpBasic.invoiceNumber : '--------'}</dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>法人名</dt>
              <dd>{corpBasic.corpName}</dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>法人名フリガナ</dt>
              <dd>{corpBasic.corpNameKana ? corpBasic.corpNameKana : '--------'}</dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>法人名（英名）</dt>
              <dd>{corpBasic.corporateNameEnglish ? corpBasic.corporateNameEnglish : '--------'}</dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>住所</dt>
              <dd>
                {corpBasic.zipcode ? '〒' + corpBasic.zipcode : '--------'}
                <br/>
                {corpBasic.corporateAddress ? corpBasic.corporateAddress : '--------'}
                <br/>
                {gmap}
              </dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>閉鎖理由</dt>
              <dd>
                {corpBasic.corporateCloseReason ? getCloseReaasonCaption(corpBasic.corporateCloseReason) : '--------'}
              </dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>代表者名/代表者役職</dt>
              <dd>
                {corpBasic.representativeName ? corpBasic.representativeName : '--------'}
                /
                {corpBasic.representativePostName ? corpBasic.representativePostName : '--------'}
              </dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>資本金</dt>
              <dd>
                {corpBasic.capital ? Number(corpBasic.capital).toLocaleString() + '円' : '--------'}
              </dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>従業員数（男性/女性）</dt>
              {employeeNumber}
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>営業品目</dt>
              {industries}
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>業種</dt>
              <dd>
              {corpBasic.shokubalaboIndustry ? corpBasic.shokubalaboIndustry: '--------'}
              </dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>企業ホームページ</dt>
              <dd>
                {corpBasic.corporateHomepage &&
                  <a className="homepage" href={corpBasic.corporateHomepage} target="_blank">
                      {corpBasic.corporateHomepage} 
                  </a>
                }
                {!corpBasic.corporateHomepage &&
                  <p>--------</p>
                }
              </dd>
            </dl>		
          </li>
          <li className="base_list">
            <dl className="dpt">
              <dt>設立年月日</dt>
              <dd>{corpBasic.foundationDate ? corpBasic.foundationDate : '--------'}</dd>
            </dl>		
          </li>
        </ul>
      </div>
      <div className="adv">
        <AdBannerH />
      </div>
      {/* <div className="content_inner">
        <div className="pr">
          <h3 className="ttl_style2">企業PR枠</h3>
          <p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
          <p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
        </div>
      </div> */}
      </DocumentMeta>
    </div>
  );
}
 
export default CorporationBasic;
