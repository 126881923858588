const Footer = () => {
    return (
      <footer>
        <ul className="dfb">
          <li><a href="https://www.mk-dt.com/?utm_source=company-search&utm_medium=referral&utm_campaign=company-search&utm_content=footer-link" target="_blank">運営会社</a></li>
          <li><a href="https://www.mk-dt.com/inquiry/?utm_source=company-search&utm_medium=referral&utm_campaign=company-search&utm_content=footer-link" target="_blank">お問い合わせ / ご意見・ご要望</a></li>
          <li><a href="/privacy/">プライバシーポリシー</a></li>
          <li><a href="/exhibit/">データの出典について</a></li>
          <li className="copy">2021&nbsp;&copy;&nbsp;Company&nbsp;Search,&nbsp;All&nbsp;Rights&nbsp;Reserved</li>
        </ul>
      </footer>
    );
}
export default Footer;
