import { useState, useEffect } from 'react';
import axios from 'axios';
import map_icon from '../img/map_icon.svg';
import type_icon from '../img/type_icon.svg';
import { PAGE_SEARCH_LIMIT } from '../const';

const RecentlyCloseCompany = ( {savePrefCd, prefCd} ) => {
  const [startupCorpList, setStartupCorpList] = useState(0);
  useEffect(() => { 
    const URL = "https://api.company-search.jp/corp/recentry/close/";
    const fetch = async () => {
      try {
        const response = await axios.get(URL);
        const arrayPost = response.data;
        setStartupCorpList(arrayPost);
      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, []);

  const corpList = [];
  if(startupCorpList) {
    startupCorpList.map((corp) => {
      let address = corp.address ? corp.address : '----';
      let industry = corp.industryName ? corp.industryName : '----';
      let foundationDate = corp.foundationDate ? corp.foundationDate : '----';
      let representativeName = corp.representativeName ? corp.representativeName : '----';
      return corpList.push(
        <li className="corp_card_layout" key={corp.corpNo}>
          <a className="card_lay_inner" href={'/corp/' + corp.corpNo + '/' }>
            <p className="corp_num">法人番号：{corp.corpNo}</p>
            <p className="close_corp">閉鎖：{corp.corporateCloseDate}</p>
            <h3 className="corp_name">{corp.corpName}</h3>
            <div className="icon_wrap">
              <p className="add"><img src={map_icon} alt=""/>{address}</p>
              <p className="indust"><img src={type_icon} alt=""/>{industry}</p>
            </div>
            <div className="dl_wrap">
              <dl className="dpt">
                <dt>設立</dt>
                <dd>{foundationDate}</dd>
              </dl>
              <dl className="dpt">
                <dt>代表</dt>
                <dd>{representativeName}</dd>
              </dl>	
            </div>
          </a>
        </li>);
    });
  }

  return (
    <section className="bankruptcy top_corp_card">
      <h2 className="ttl_style2">最近倒産した企業</h2>
      <ul className="dfb">
        {corpList}
      </ul>
      <a className="more" href={"/search/?offset=0&limit=" + PAGE_SEARCH_LIMIT + "&recentlyCloseMode=1"}>もっと見る</a>
    </section>
  );
}
 
export default RecentlyCloseCompany;
