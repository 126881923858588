import DocumentMeta from 'react-document-meta';
import Breadcrumb from './parts/breadcrumb';
const PrivacyPolicy = () => {
  var title = "プライバシーポリシー - 企業情報・法人情報検索のCompany Search";
  var desc = "Company Searchのプライバシーポリシーです。 Company Searchは全国約500万件の企業の住所やホームページURLなどの企業情報・法人情報を掲載し、様々な条件で検索することが出来ます。 - 企業情報・法人情報検索のCompany Search";
  const meta = {
    title: title,
    description: desc,
    robots: "all",
    meta: {
      property: {
        'og:url': 'https://company-search.jp/privacypolicy/',
        'og:type': 'website',
        'og:title': title,
        'og:description': desc,
        'og:site_name': 'Company Search - 企業情報・法人情報検索',
        'og:image': 'https://company-search.jp/logo.png',
      }
    }
  };
  return (
    <div className="wrapper privacy_policy no_sidenav">
      <DocumentMeta {...meta}>
      <Breadcrumb props={"プライバシーポリシー"}/>
      <section class="under_mv">
        <div class="biginner">
          <div class="ttl">
            <h1>プライバシーポリシー</h1>
          </div>
        </div>
      </section>
      <div class="content_wrap">
        <main class="main_wrap">
          <div class="content_inner">
            <ul>
              <li>
                <dl>
                  <dt class="ttl_style2">個人情報の管理</dt>
                  <dd>
                    <p>個人情報を取り扱うにあたっては、「個人情報の保護に関する法律」をはじめとする個人情報の保護に関する法令、ガイドラインおよび本プライバシーポリシーを遵守いたします。</p>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt class="ttl_style2">個人情報の第三者への開示</dt>
                  <dd>
                    <p>当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。</p>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt class="ttl_style2">アクセス解析ツールについて</dt>
                  <dd>
                    <p>
                      当サイトは、トラフィックデータの収集のためにCookieを使用しています。
                      <br/>
                      トラフィックデータは匿名で収集されており、個人を特定するものではありません。
                      <br/>
                      Cookieは、お使いのブラウザの設定で拒否することが出来ます。
                    </p>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
          {/* <div class="contact_wrap">
            <div class="biginner contact_ttl">
              <h2>個人情報のお問い合わせについて</h2>
            </div>
            <div class="content_inner">
              <a href="" target="_blank" class="contact_btn">お問い合わせフォームへ</a>
            </div>	
          </div> */}
        </main>
      </div>
      </DocumentMeta>
    </div>
  );
}
 
export default PrivacyPolicy;
