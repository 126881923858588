import { useState, useEffect } from 'react';
import axios from 'axios';
import search_icon from './img/search_icon.svg';
import type_icon from './img/type_icon.svg';
import DocumentMeta from 'react-document-meta';
import PrefLinkList from './parts/pref_link_list';
import RecentlyStartupCompany from './parts/recently_startup_companies';
import RecentlyCloseCompany from './parts/recently_close_companies';
import AdBannerV from './parts/ad_banner_v';
import { NO_SELECT_VALUE, PREFECTURES_OPTIONS, INDUSTRIES_LINK_LIST, PAGE_SEARCH_LIMIT } from './const';

const FrontPage = ( {corpNo} ) => {
  const [corpStatus, setCorpStatus] = useState(0);
  const [prefCd, setPrefCd] = useState(NO_SELECT_VALUE);
  const [corpWord, setCorpWord] = useState("");
  const [invoiceFlg, setInvoiceFlg] = useState(false);

  useEffect(() => { 
    const fetch = async () => {
      try {
        const URL_STATUS = "https://api.company-search.jp/corp/status/";
        const response_status = await axios.get(URL_STATUS);
        const post_status = response_status.data;
        post_status.count = Number(post_status.count).toLocaleString();
        setCorpStatus(post_status);

      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, [corpNo]);

  function searchPageDecorate(lOffset, lLimit){
    let URL = "/search/"
      + "?offset=" + lOffset
      + "&limit=" + lLimit;

    if (prefCd !== NO_SELECT_VALUE) {
      URL += "&pref=" + String(prefCd);
    }
    if (corpWord) {
      URL += "&corpWord=" + encodeURIComponent(String(corpWord));
    }

    if (invoiceFlg) {
      URL += "&invoiceFlg=1";
    }

    return URL;
  }

  async function handleSearch(event) {
    if (event.type !== "click" && event.keyCode !== 13) {
      return false;
    }
    const lOffset = 0;
    const lLimit = PAGE_SEARCH_LIMIT;
    const URL = searchPageDecorate(lOffset, lLimit);
    window.location.href = URL
  }

  function savePrefCd(event) {
    setPrefCd(event.target.value);
  };

  function saveCorpWord(event) {
    setCorpWord(event.target.value);
  };

  const toggleInvoiceFlg = () => {
    setInvoiceFlg(!invoiceFlg);
  }

  var title = "Company Search - 企業情報・法人情報検索";
  var desc = "Company Searchは全国約500万件の法人情報・企業情報（住所やホームページのURL、適格請求書（インボイス）発行事業者登録番号など）を掲載。全国の上場、未上場企業はもちろん、最近設立された企業一覧・最近倒産した企業一覧からの検索も可能。営業リストの作成や取引先企業の確認など、企業探しをサポートします。";
  const meta = {
    title: title,
    description: desc,
    robots: "all",
    meta: {
      property: {
        'og:url': 'https://company-search.jp/',
        'og:type': 'website',
        'og:title': title,
        'og:description': desc,
        'og:site_name': 'Company Search - 企業情報・法人情報検索',
        'og:image': 'https://company-search.jp/logo.png',
      }
    }
  };

  return (
    <div className="wrapper top">
      <DocumentMeta {...meta}>
      <section className="top_mv">
        <div className="biginner">
          <h1 className="ttl_style">企業情報を検索</h1>
          <div className="dfb top_search_box">
            <input type="text" className="top_txt" placeholder="企業名・法人番号" onChange={saveCorpWord} onKeyDown={(e) => handleSearch(e)}/>
            <div className="select_wrap">
              <select className="top_select" name="" value={prefCd} onChange={savePrefCd}>
                <option value="0">全国</option>
                <PREFECTURES_OPTIONS />
              </select>	
            </div>
            <button className="top_search_btn" onClick={handleSearch}>
              <img src={search_icon} alt="SearchIcon" />
            </button>
          </div>
          <div className="dfb top_check_box">
            <input
              type="checkbox"
              id="invoice-top"
              name="invoice-top"
              onChange={toggleInvoiceFlg}
            />
            <b><label htmlFor="invoice-top">適格請求書（インボイス）<span className="inblock">発行事業者登録番号あり</span></label></b>
          </div>
          <p className="mvtxt">
            全国{corpStatus.count}社（最終更新日時: {corpStatus.last_update}）
          </p>
        </div>
      </section>
      <div className="content_wrap">
        <main className="main_wrap">
          <PrefLinkList/>
          <section className="top_search indust_search">
            <div className="inner">
              <h2><img src={type_icon} alt="TypeIcon"/>営業品目から探す<span className="sp_ib"></span></h2>
              <div className="ul_wrap ind_ul">
                <ul className="dfb">
                  <INDUSTRIES_LINK_LIST/>
                </ul>	
              </div>
            </div>
          </section>
          <RecentlyStartupCompany />
          <RecentlyCloseCompany />
        </main>
        <aside className="sidenav">
          <div className="sidenav_inner">
            <AdBannerV />
          </div>
        </aside>	
      </div>
      </DocumentMeta>
    </div>
  );
}
 
export default FrontPage;
