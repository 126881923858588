import { useEffect } from "react";

export const useTracking = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    // gtag('config', 'G-4LSFTZRDJ4');
    gtag('config', 'G-4LSFTZRDJ4', {
        // page_title : document.title,
        page_location: window.location.protocol + '//'+ 
            window.location.hostname + 
            window.location.pathname + 
            window.location.search
      });
  }, []);
};
