import { CAPITALS_OPTIONS } from "../const"

const CapitalSelect = ({capitalFromCd, saveCapitalFromCd, capitalToCd, saveCapitalToCd}) =>{
  return(
    <div className="select_wrap_double">
      <div className="select_wrap">
        <select className="pre_select" name="" value={capitalFromCd} onChange={saveCapitalFromCd}>
          <option value="0">----------</option>
            <CAPITALS_OPTIONS capitalCd={capitalToCd} fromTo={"from"}/>
          </select>
      </div>
      〜
      <div className="select_wrap">
        <select className="pre_select" name="" value={capitalToCd} onChange={saveCapitalToCd}>
          <option value="0">----------</option>
            <CAPITALS_OPTIONS capitalCd={capitalFromCd} fromTo={"to"}/>
        </select>
      </div>
    </div>
  )
}

export default CapitalSelect;