import {INDUSTRIES_OPTIONS} from '../const';

const IndustrySelectBox = ( {saveIndustryCd, industryCd} ) => {
  return (
    <div className="select_wrap">
      <select className="pre_select" name="" value={industryCd} onChange={saveIndustryCd}>
        <option value="0">全ての営業品目</option>
        <INDUSTRIES_OPTIONS/>
      </select>
    </div>
  );
}
 
export default IndustrySelectBox;
