import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Search from './search';
import FrontPage from './frontpage'
import PrivacyPolicy from './privacy_policy'
import Exhibit from'./exhibit'
import CorporationBase from './corporation_base'
import NotFound from './not_found'
import Footer from './parts/footer'
import Header from './parts/header';
import { useTracking } from './hooks/useTracking'
import './css/common.css'
import './css/loading.css'
import './css/keyframe.css'
import './css/reset.css'

function App() {
  useTracking()

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/search/">
          <Search/>
        </Route>
        <Route exact path="/privacy/">
          <Header/>
          <PrivacyPolicy/>
        </Route>
        <Route exact path="/exhibit/">
          <Header/>
          <Exhibit/>
        </Route>
        <Route path="/corp/:corpNo(\d+)/cert/">
          <Header/>
          <CorporationBase mode={'cert'}/>
        </Route>
        <Route path="/corp/:corpNo(\d+)/commendation/">
          <Header/>
          <CorporationBase mode={'commendation'} />
        </Route>
        <Route path="/corp/:corpNo(\d+)/subsidy/">
          <Header/>
          <CorporationBase mode={'subsidy'} />
        </Route>
        <Route path="/corp/:corpNo(\d+)/procurement/">
          <Header/>
          <CorporationBase mode={'procurement'} />
        </Route>
        <Route path="/corp/:corpNo(\d+)/workplace/">
          <Header/>
          <CorporationBase mode={'workplace'} />
        </Route>
        <Route path="/corp/:corpNo(\d+)/">
          <Header/>
          <CorporationBase mode={'basic'} />
        </Route>
        <Route exact path="/">
          <Header/>
          <FrontPage/>
        </Route>
        <Route>
          <Header/>
          <NotFound/>
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
