import { Link } from 'react-router-dom';
import { useState } from 'react';
import map_icon from '../img/map_icon.svg';
import type_icon from '../img/type_icon.svg';

const SearchResultList = ( {props} ) => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const [windowDimensions] = useState(getWindowDimensions());
  const resultList = [];
  if(props.length > 0) {
    props.map((prop) => {
      let address = prop.address ? prop.address : '----';
      let industry = '----';
      if (prop.industryName && prop.industryName.length > 0) {
        industry = prop.industryName.join(', ')
      }
      let foundationDate = prop.foundationDate ? prop.foundationDate : '----';
      let representativeName = prop.representativeName ? prop.representativeName : '----';
      const to = {
        pathname: "/corp/" + prop.corpNo + "/",
        url: prop.url,
        topic: prop.topic 
      };

      return resultList.push(
        <li className="corp_card_layout"  key={prop.corpNo}>
          <button className="fav_button" style={{display:'none'}}>
            <span className="pc txt">お気に入り追加</span>
          </button>
          <Link className="card_lay_inner" to={to}>
            <div className="ttl_wrap">
              <p className="corp_num">法人番号：{prop.corpNo}</p>
              {prop.corporateCloseDate　&&
              <p className="close_corp">閉鎖：{prop.corporateCloseDate}</p>
              }
              <h3 className="corp_name">{prop.corpName}</h3>
            </div>
            <div className="icon_wrap">
              <p className="add">
                <img src={map_icon} alt="map" />
                {address}
              </p>
              <p className="indust">
                <img src={type_icon} alt="type" />
                {industry}
              </p>
            </div>
            <div className="dl_wrap">
              <dl className="dpt">
                <dt>設立</dt>
                <dd>{foundationDate}</dd>
              </dl>
              <dl className="dpt">
                <dt>代表</dt>
                <dd>{representativeName}</dd>
              </dl>	
            </div>
          </Link>
        </li>
      );
    });
    return (
      <ul>
        {resultList}
      </ul>
    );
  } else {
    return(
      <div className="contents">
        <div style={{height: windowDimensions.height - 441}}>
          &nbsp;
        </div>
      </div>
    );
  }
}
 
export default SearchResultList;
