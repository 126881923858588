import { useState, useEffect } from 'react';
import axios from 'axios';

const TownSelectBoxS = ( {prefCd, saveTownCd, townCd} ) => {
  const [townList, setTownList] = useState(0);
  useEffect(() => {
    const URL = "https://api.company-search.jp/town/?q=" + prefCd;
    const fetch = async () => {
      try {
        if (prefCd !== '0') {
          const response = await axios.get(URL);
          const arrayPost = response.data;
          setTownList(arrayPost);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, [prefCd]);

  const optionList = [];
  if(townList) {
    townList.map((town) => {
      return optionList.push(<option value={town.code} key={town.code}>{town.name}</option>);
    });
  }

  let isDisabled = String(prefCd) === "0";
  let disabledClass = String(prefCd) === "0" ? 'disabled' : '';

  return (
    <div id="townSelectWrap_s" className={'select_wrap' + ' ' + disabledClass}>
      <select 
        className="town_select"
        id="town_select_s"
        name=""
        disabled={isDisabled}
        onChange={saveTownCd}
        value={townCd}
      >
        <option value="0">全ての市区町村</option>
        {optionList}
      </select>
    </div>
  );
};

export default TownSelectBoxS;
