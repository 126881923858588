import { useState, useEffect } from 'react';
import axios from 'axios';
import map_icon from '../img/map_icon.svg';
import type_icon from '../img/type_icon.svg';

const SameIndustryCorp = ( {corpNo} ) => {
  const [sameIndustryCorpList, setSameIndustryCorpList] = useState(0);
  const [isNone, setIsNone] = useState(false);
  useEffect(() => { 
    const URL = "https://api.company-search.jp/corp/" + corpNo + "/same-industry-corp/?offset=0&limit=5";
    const fetch = async () => {
      try {
        const response = await axios.get(URL);
        const arrayPost = response.data;
        setSameIndustryCorpList(arrayPost.payload);
      } catch (error) {
        if (error.response.status === 404) {
          setIsNone(true);
        }
        console.error(error);
      }
    }
    fetch();
  }, []);

  if (isNone) {
    return null;
  }

  const corpList = [];
  if(sameIndustryCorpList) {
    sameIndustryCorpList.map((corp) => {
      let address = corp.address ? corp.address : '----';
      let industryName = corp.industryName ? corp.industryName.join(" / ") : '----';
      let foundationDate = corp.foundationDate ? corp.foundationDate : '----';
      let representativeName = corp.representativeName ? corp.representativeName : '----';
      return corpList.push(
        <li className="corp_card_layout">
          <a className="card_lay_inner" href={'/corp/' + corp.corpNo + '/' }>
            <p className="corp_num">法人番号：{corp.corpNo}</p>
            <h3 className="corp_name">{corp.corpName}</h3>
            <div className="icon_wrap">
              <p className="add"><img src={map_icon} alt="map"/>{address}</p>
              <p className="indust"><img src={type_icon} alt="type"/>{industryName}</p>
            </div>
            <div className="dl_wrap">
              <dl className="dpt">
                <dt>設立</dt>
                <dd>{foundationDate}</dd>
              </dl>
              <dl className="dpt">
                <dt>代表</dt>
                <dd>{representativeName}</dd>
              </dl>	
            </div>
          </a>
        </li>);
    });
  }

  return (
    <div className="sidenav_card_inner">
      <h2 className="ttl_style2">同業種の法人</h2>
      <ul>
        {corpList}
      </ul>
    </div>
  );
}
 
export default SameIndustryCorp;
