import { useEffect, useState } from "react";

/** 検索モード: 設立 */
const MODE_ESTABLISHED = "est";
/** 検索モード: 倒産・廃業 */
const MODE_CLOSED = "close";
/** 「年」の選択肢の開始 */
const OPTION_YEAR_MIN = 1900;

/**
 * 設立・倒産企業の検索パーツ
 */
const EstCloseDateSelect = ({
  recentlyStartupMode,
  recentlyCloseMode,
  year,
  month,
  day,
  setYear,
  setMonth,
  setDay,
  setRecentlyStartupMode,
  setRecentlyCloseMode,
  onModeChange,
}) => {
  // 検索モード
  const defaultMode = getDefaultMode(recentlyStartupMode, recentlyCloseMode);
  const [mode, setMode] = useState(defaultMode);

  useEffect(
    // NOTE: 初回レンダリング用
    () => onModeChange(defaultMode === MODE_CLOSED),
    [defaultMode,onModeChange]
  );

  // 検索モードが変更されたときの処理 (設立 or 倒産)
  const handleModeChange = (e) => {
    const currentMode = e.target.value;
    onModeChange(currentMode === MODE_CLOSED);
    if (currentMode === mode) {
      // 選択されたモードが現在のモードと同じ場合OFFにする
      setMode("");
      setRecentlyStartupMode(false);
      setRecentlyCloseMode(false);
      return;
    }
    setMode(currentMode);
    setRecentlyStartupMode(currentMode === MODE_ESTABLISHED);
    setRecentlyCloseMode(currentMode === MODE_CLOSED);
  };
  /**
   * 年が変更されたときの処理
   */
  const handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value);
    setYear(selectedYear);
    // 選択された年月に応じて日を更新
    updateDays(selectedYear, month);
  };
  /**
   * 月が変更されたときの処理
   */
  const handleMonthChange = (e) => {
    const selectedMonth = parseInt(e.target.value);
    setMonth(selectedMonth);
    // 選択された年月に応じて日を更新
    updateDays(year, selectedMonth);
  };
  /**
   * 日が変更されたときの処理
   */
  const handleDayChange = (e) => {
    const selectedDay = parseInt(e.target.value);
    setDay(selectedDay);
  };

  /**
   * 年や月が変更されたときに日を更新する関数
   * @param {number} selectedYear 年
   * @param {number} selectedMonth 月
   */
  const updateDays = (selectedYear, selectedMonth) => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    // 選択された月の日数に応じて日の選択肢を更新
    if (day > daysInMonth) {
      setDay(1);
    }
  };

  // 年の選択肢を生成
  const yearOptions = getYearOptions();
  // 月の選択肢を生成
  const monthOptions = getMonthOptions();
  // 日の選択肢を生成
  const dayOptions = getDayOptions(year, month);

  return (
    <>
      <p>設立・倒産企業</p>
      <div className="side_tab">
        <label>
          <input
            type="checkbox"
            name="est-pc"
            value={MODE_ESTABLISHED}
            onChange={handleModeChange}
            checked={mode === MODE_ESTABLISHED}
          />
          設立が
        </label>
        <label>
          <input
            type="checkbox"
            name="est-pc"
            value={MODE_CLOSED}
            onChange={handleModeChange}
            checked={mode === MODE_CLOSED}
          />
          倒産・廃業が
        </label>
      </div>
      <div className="side_tab_inner">
        <div className="select_wrap_date">
          <div className="select_wrap">
            <select
              className="close_year"
              value={year}
              onChange={handleYearChange}
              disabled={mode === ''}
            >
              {yearOptions}
            </select>
          </div>
          <div className="select_wrap select">
            <select
              className="close_month"
              value={month}
              onChange={handleMonthChange}
              disabled={mode === ''}
            >
              {monthOptions}
            </select>
          </div>
          <div className="select_wrap select">
            <select
              className="close_day"
              value={day}
              onChange={handleDayChange}
              disabled={mode === ''}
            >
              {dayOptions}
            </select>
          </div>
        </div>
        <p className="note">以降の企業</p>
      </div>
    </>
  );
};

export default EstCloseDateSelect;

/**
 * 検索モードのデフォルト値を取得
 * @param {boolean} recentlyStartupMode 最近設立された企業のフラグ
 * @param {boolean} recentlyCloseMode 最近設立された倒産のフラグ
 * @returns 検索モード文字列
 */
function getDefaultMode(recentlyStartupMode, recentlyCloseMode) {
  if (recentlyStartupMode && !recentlyCloseMode) {
    return MODE_ESTABLISHED;
  }
  if (!recentlyStartupMode && recentlyCloseMode) {
    return MODE_CLOSED;
  }
  // デフォルトは未設定。両方のフラグがONの場合(通常ありえない)も未設定にしておく
  return "";
}

/**
 * プルダウンの「年」の選択肢を作成
 * @returns optionタグの配列
 */
function getYearOptions() {
  const yearOptions = [];
  const currentYear = new Date().getFullYear();
  for (let i = OPTION_YEAR_MIN; i <= currentYear; i++) {
    yearOptions.push(
      <option key={i} value={i}>
        {i}年
      </option>
    );
  }
  return yearOptions;
}

/**
 * プルダウンの「月」の選択肢を作成
 * @returns optionタグの配列
 */
function getMonthOptions() {
  const monthOptions = [];
  for (let i = 1; i <= 12; i++) {
    monthOptions.push(
      <option key={i} value={i}>
        {i}月
      </option>
    );
  }
  return monthOptions;
}

/**
 * プルダウンの「日」の選択肢を作成
 * @param {number} year 対象年
 * @param {number} month 対象月
 * プルダウンの「日」の選択肢を作成
 */
function getDayOptions(year, month) {
  const daysInMonth = new Date(year, month, 0).getDate();
  const dayOptions = [];
  for (let i = 1; i <= daysInMonth; i++) {
    dayOptions.push(
      <option key={i} value={i}>
        {i}日
      </option>
    );
  }
  return dayOptions;
}
