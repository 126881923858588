import { useState, useEffect } from 'react';
import axios from 'axios';
import AdBannerH from '../parts/ad_banner_h';
import DocumentMeta from 'react-document-meta';

const CorporationWorkplace = ( {corpNo, corpName, prefName, addressName} ) => {
  const [corpWorkplace, setCorpWorkplace] = useState({});
  useEffect(() => { 
    const URL = "https://api.company-search.jp/corp/" + corpNo + "/workplace/";
    const fetch = async () => {
      try {
        const response = await axios.get(URL);
        const post = response.data;
        setCorpWorkplace(post);
      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, [corpNo]);

  let averageWorkingYears = '';
  if (corpWorkplace.averageWorkingYearsMale || corpWorkplace.averageWorkingYearsFemale) {
    let averageWorkingYearsMale = corpWorkplace.averageWorkingYearsMale ? corpWorkplace.averageWorkingYearsMale + '年' : '--';
    let averageWorkingYearsFemale = corpWorkplace.averageWorkingYearsFemale ? corpWorkplace.averageWorkingYearsFemale + '年' : '--';
    let averageWorkingYearsRange = corpWorkplace.averageWorkingYearsRange ? '（' + corpWorkplace.averageWorkingYearsRange + '）' : '';
    averageWorkingYears = averageWorkingYearsRange + averageWorkingYearsMale + '(男性)/' + averageWorkingYearsFemale  + '(女性)'
  } else {
    averageWorkingYears = '--------';
  }

  let amongWorkers = '';
  if (corpWorkplace.femaleWorkersAmongWorkers) {
    let maleWorkersAmongWorkers = (100 - corpWorkplace.averageWorkingYearsMale) + '%';
    let femaleWorkersAmongWorkers = corpWorkplace.femaleWorkersAmongWorkers ? corpWorkplace.femaleWorkersAmongWorkers + '%' : '--';
    let amongWorkersRange = corpWorkplace.femaleWorkersAmongWorkersRange ? '（' + corpWorkplace.femaleWorkersAmongWorkersRange + '）' : '';
    amongWorkers = amongWorkersRange + maleWorkersAmongWorkers + '(男性)/' + femaleWorkersAmongWorkers  + '(女性)'
  } else {
    amongWorkers = '--------';
  }

  let managersNumber = '';
  if ((corpWorkplace.femaleManagersNumber !== null) && corpWorkplace.totalManagersNumber) {
    let maleManagersNumber = (corpWorkplace.totalManagersNumber - corpWorkplace.femaleManagersNumber) + '人';
    let femaleManagersNumber = corpWorkplace.femaleManagersNumber + '人';
    managersNumber = maleManagersNumber + '(男性)/' + femaleManagersNumber  + '(女性)'
  } else {
    managersNumber = '--------';
  }

  let executiveNumber = '';
  if ((corpWorkplace.femaleExecutiveNumber !== null) && corpWorkplace.totalExecutiveNumber) {
    let maleExecutiveNumber = (corpWorkplace.totalExecutiveNumber - corpWorkplace.femaleExecutiveNumber) + '人';
    let femaleExecutiveNumber = corpWorkplace.femaleExecutiveNumber + '人';
    executiveNumber = maleExecutiveNumber + '(男性)/' + femaleExecutiveNumber  + '(女性)'
  } else {
    executiveNumber = '--------';
  }

  let childcareLeaveTargetNumber = '';
  if (corpWorkplace.childcareLeaveTargetMaleNumber || corpWorkplace.childcareLeaveTargetFemaleNumber) {
    let maleTargetNumber = corpWorkplace.childcareLeaveTargetMaleNumber ? corpWorkplace.childcareLeaveTargetMaleNumber + '人' : 0  + '人';
    let femaleTargetNumber =  corpWorkplace.childcareLeaveTargetFemaleNumber ? corpWorkplace.childcareLeaveTargetFemaleNumber + '人' : 0  + '人';
    childcareLeaveTargetNumber = maleTargetNumber + '(男性)/' + femaleTargetNumber  + '(女性)'
  } else {
    childcareLeaveTargetNumber = '--------';
  }

  let childcareLeavePersonNumber = '';
  if (corpWorkplace.childcareLeavePersonMaleNumber || corpWorkplace.childcareLeavePersonFemaleNumber) {
    let malePersonNumber = corpWorkplace.childcareLeavePersonMaleNumber ? corpWorkplace.childcareLeavePersonMaleNumber + '人' : 0  + '人';
    let femalePersonNumber =  corpWorkplace.childcareLeavePersonFemaleNumber ? corpWorkplace.childcareLeavePersonFemaleNumber  + '人': 0  + '人';
    childcareLeavePersonNumber = malePersonNumber + '(男性)/' + femalePersonNumber  + '(女性)'
  } else {
    childcareLeavePersonNumber = '--------';
  }

  const noConnector = prefName ||  addressName ? 'の' : '';
  var title = corpName + "（" + corpNo + "）企業職場情報 - 企業情報・法人情報検索のCompany Search";
  var desc = prefName + addressName + noConnector + corpName + "（" + corpNo + "）企業職場情報です。Company Searchは全国約500万件の企業から様々な条件で企業を検索することが出来ます。 - 企業情報・法人情報検索のCompany Search";
  const meta = {
    title: title,
    description: desc,
    robots: "all",
    meta: {
      property: {
        'og:url': 'https://company-search.jp/corp/' + corpNo + '/workplace/',
        'og:type': 'website',
        'og:title': title,
        'og:description': desc,
        'og:site_name': 'Company Search - 企業情報・法人情報検索',
        'og:image': 'https://company-search.jp/logo.png',
      }
    }
  };
  
  let trainingPrograms = '--------';
  if (corpWorkplace.trainingPrograms !== undefined){
    trainingPrograms = corpWorkplace.trainingPrograms;
  }

  let mentorshipPrograms = '--------';
  if (corpWorkplace.mentorshipPrograms !== undefined){
    mentorshipPrograms = corpWorkplace.mentorshipPrograms;
  }

  let selfDevelopmentPrograms = '--------';
  if (corpWorkplace.selfDevelopmentPrograms !== undefined){
    selfDevelopmentPrograms = corpWorkplace.selfDevelopmentPrograms;
  }

  let careerConsultationPrograms = '--------';
  if (corpWorkplace.mentorshipPrograms !== undefined){
    careerConsultationPrograms = corpWorkplace.careerConsultationPrograms;
  }

  let internalCertificationPrograms = '--------';
  if (corpWorkplace.internalCertificationPrograms !== undefined){
    internalCertificationPrograms = corpWorkplace.internalCertificationPrograms;
  }

  return (
    <div className="content subsidy_info show">
      <DocumentMeta {...meta}>
      <h2 className="ttl_style3">職場情報</h2>
      <div className="adv">
        <AdBannerH />
      </div>
      <div class="content_inner no_pb">
        <ul class="info_table_layout">
          <li class="workplace_list">
            <dl class="dpt">
              <dt>平均勤続勤務年数</dt>
              <dd>{averageWorkingYears}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>正社員の平均継続勤務年数</dt>
              <dd>{corpWorkplace.regularEmployeesAverageWorkingYears ? corpWorkplace.regularEmployeesAverageWorkingYears + '年' : '--------'}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>正社員の従業員の平均年齢</dt>
              <dd>{corpWorkplace.averageAge ? corpWorkplace.averageAge + '歳' : '--------'}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>月平均所定外労働時間</dt>
              <dd>{corpWorkplace.workingHoursOutOfWithPrescribed ? corpWorkplace.workingHoursOutOfWithPrescribed + '時間' : '--------'}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>労働者の割合（性別）</dt>
              <dd>{amongWorkers}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>管理職人数</dt>
              <dd>{managersNumber}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>役員人数</dt>
              <dd>{executiveNumber}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>育児休業対象者数</dt>
              <dd>{childcareLeaveTargetNumber}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>育児休業取得者数</dt>
              <dd>{childcareLeavePersonNumber}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>研修制度</dt>
              <dd>{trainingPrograms}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>メンター制度</dt>
              <dd>{mentorshipPrograms}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>自己啓発支援制度</dt>
              <dd>{selfDevelopmentPrograms}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>キャリアコンサルティング制度</dt>
              <dd>{careerConsultationPrograms}</dd>
            </dl>		
          </li>
          <li class="workplace_list">
            <dl class="dpt">
              <dt>社内検定制度</dt>
              <dd>{internalCertificationPrograms}</dd>
            </dl>		
          </li>
        </ul>
      </div>
      <div className="adv">
        <AdBannerH />
      </div>
      </DocumentMeta>
    </div>
  );
}
 
export default CorporationWorkplace;
