import AdBannerH from './parts/ad_banner_h';
import Breadcrumb from './parts/breadcrumb';
import search_icon from './img/search_icon.svg';
import logo from './img/logo.png';

const NotFound = () => {
  const meta = {
    title: "このページは利用できません - 企業情報・法人情報検索のCompany Search",
    description: "このページは利用できません（404 Not Found）。URLをご確認ください。Company Searchは全国約500万件の企業の住所やホームページURLなどの企業情報・法人情報を掲載し、様々な条件で検索することが出来ます。 - 企業情報・法人情報検索のCompany Search",
    robots: "all",
  };
  return (
    <div className="wrapper no_sidenav">
      <Breadcrumb props={"404 Not Found"} />
      <div className="content_wrap">
        <main className="main_wrap">
          <div className="content_inner">
            <div className="adv">
              <AdBannerH />
            </div>
            <div className="notfound_inner">
                <h1>このページは利用できません。</h1>
                <br/><br/>
                <small>企業情報を検索</small>
            </div>
            <div className="not_found_search">
              <div className="dfb not_found_menu">
                <a href="/">
                  <img src={logo} alt="Company Search - 企業情報・法人情報検索" />
                </a>
                <div revedit="" />
                <div className="dfb menu_wrap">
                  <button className="search">
                    <img src={search_icon} alt="search"/>
                  </button>
                  <div className="hamburger" style={{display: 'none'}}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="adv">
              <AdBannerH />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
 
export default NotFound;
