import DocumentMeta from 'react-document-meta';
import Breadcrumb from './parts/breadcrumb';
const Exhibit = () => {
  var title = "データの出典について - 企業情報・法人情報検索のCompany Search";
  var desc = "Company Searchで使用しているデータの出典についての説明です。Company Searchは全国約500万件の企業の住所やホームページURLなどの企業情報・法人情報を掲載し、様々な条件で検索することが出来ます。 - 企業情報・法人情報検索のCompany Search";
  const meta = {
    title: title,
    description: desc,
    robots: "all",
    meta: {
      property: {
        'og:url': 'https://company-search.jp/exhibit/',
        'og:type': 'website',
        'og:title': title,
        'og:description': desc,
        'og:site_name': 'Company Search - 企業情報・法人情報検索',
        'og:image': 'https://company-search.jp/logo.png',
      }
    }
  };


  return (
    <div class="wrapper exhibit no_sidenav">
      <DocumentMeta {...meta}>
      <Breadcrumb props={"データの出典について"}/>
      <section class="under_mv">
        <div class="biginner">
          <div class="ttl">
            <h1>データの出典について</h1>
          </div>
        </div>
      </section>
      <div class="content_wrap">
        <main class="main_wrap">
          <div class="content_inner">
            <ul>
              <li>
                <dl>
                  <dt class="ttl_style2">法人番号・基本情報・届出認定情報・職場情報・補助金情報・財務情報</dt>
                  <dd>
                    <p>経済産業省の法人活動情報掲載サイト「gBizInfo」のデータを加工して作成しています。</p>
                    <p>
                      <a href="https://info.gbiz.go.jp/" target="_blank">
                        https://info.gbiz.go.jp/
                      </a>
                    </p>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt class="ttl_style2">適格請求書発行事業者登録番号・基本情報・職場情報</dt>
                  <dd>
                    <p>国税庁適格請求書発行事業者公表サイト（国税庁）のデータを加工して作成しています。</p>
                    <p>
                      <a href="https://www.invoice-kohyo.nta.go.jp/" target="_blank">
                        https://www.invoice-kohyo.nta.go.jp/
                      </a>
                    </p>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </main>
      </div>
      </DocumentMeta>
    </div>
  );
}
 
export default Exhibit;
