import search_icon from '../img/search_icon.svg';
import logo from '../img/logo.png';
import ximg from '../img/x.svg';
import IndustrySelectBox from './industry_select'
import TownSelectBox from './town_select'
import CapitalSelect from './capital_select';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScriptTag from 'react-script-tag';
import { NO_SELECT_VALUE, PAGE_SEARCH_LIMIT } from '../const'
import EstCloseDateSelect from './est_close_date_select';

const Header = (props) => {
  const [prefCd, setPrefCd] = useState(props.pPrefCd ? props.pPrefCd : NO_SELECT_VALUE);
  const [townCd, setTownCd] = useState(props.pTownCd ? props.pTownCd : NO_SELECT_VALUE);
  const [industryCd, setIndustryCd] = useState(props.pIndustryCd ? props.pIndustryCd : NO_SELECT_VALUE);
  const [capitalFromCd, setCapitalFromCd] = useState(props.pCapitalFromCd ? props.pCapitalFromCd : NO_SELECT_VALUE);
  const [capitalToCd, setCapitalToCd] = useState(props.pCapitalToCd ? props.pCapitalToCd : NO_SELECT_VALUE);
  const [corpWord, setCorpWord] = useState(props.pCorpWord ? props.pCorpWord : "");
  const [includeStopCorp, setIncludeStopCorp] = useState(props.pIncludeStopCorp ? props.pIncludeStopCorp : "");
  const [diableIncludeStopCorp, setDiableIncludeStopCorp] = useState(false);
  const [recentlyStartupMode, setRecentlyStartupMode] = useState(props.pRecentlyStartupMode ? props.pRecentlyStartupMode : "");
  const [recentlyCloseMode, setRecentlyCloseMode] = useState(props.pRecentlyCloseMode ? props.pRecentlyCloseMode : "");
  const [invoiceFlg, setInvoiceFlg] = useState(props.pInvoiceFlg);

  // 設立・倒産企業の検索の検索条件
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30); // NOTE: デフォルトは30日前
  const [year, setYear] = useState(
    props.pFromYear ? props.pFromYear : defaultDate.getFullYear()
  );
  const [month, setMonth] = useState(
    props.pFromMonth ? props.pFromMonth : defaultDate.getMonth() + 1
  );
  const [day, setDay] = useState(
    props.pFromDay ? props.pFromDay : defaultDate.getDate()
  );

  function savePrefCd(event) {
    setPrefCd(event.target.value);
    setTownCd('0');
  };

  function saveTownCd(event) {
    setTownCd(event.target.value);
  };

  function saveIndustryCd(event) {
    setIndustryCd(event.target.value);
  };

  function saveCapitalFromCd(event){
    setCapitalFromCd(event.target.value)
  }

  function saveCapitalToCd(event){
    setCapitalToCd(event.target.value)
  }

  function saveCorpWord(event) {
    setCorpWord(event.target.value);
  };

  function saveIncludeStopCorp(event) {
    setIncludeStopCorp(event.target.checked);
  };

  const toggleInvoiceFlg = () => {
    setInvoiceFlg(!invoiceFlg);
  }

  /**
   * 「設立・倒産企業」の検索条件の設定状況によって「倒産・廃業した企業を含める」の条件を設定する
   * @param {boolean} isCloseSelected 倒産企業を検索する場合、true
   */
  const enableStopCorp = (isCloseSelected) => {
    if (isCloseSelected) {
      // 「倒産・廃業が」ボタンを設定されている場合はチェックボックスをチェック済みとし、非活性とする。
      setDiableIncludeStopCorp(true);
      setIncludeStopCorp(true);
    } else {
      setDiableIncludeStopCorp(false);
    }
  }

  function searchPageDecorate(lOffset, lLimit){
    let URL = "/search/"
      + "?offset=" + lOffset 
      + "&limit=" + lLimit;

    if (prefCd !== NO_SELECT_VALUE) {
      URL += "&pref=" + String(prefCd);
    }
    if (industryCd !== NO_SELECT_VALUE) {
      URL += "&industry=" + String(industryCd);
    }
    if (capitalFromCd !== NO_SELECT_VALUE) {
      URL += "&capitalFrom=" + String(capitalFromCd);
    }
    if (capitalToCd !== NO_SELECT_VALUE) {
      URL += "&capitalTo=" + String(capitalToCd);
    }
    if (townCd !== NO_SELECT_VALUE) {
      URL += "&town=" + String(townCd);
    }
    if (corpWord) {
      URL += "&corpWord=" + encodeURIComponent(String(corpWord));
    }
    if (includeStopCorp) {
      URL += "&corpCloseFlg=1";
    }
    if (recentlyStartupMode) {
      URL += "&recentlyStartupMode=1";
    }
    if (recentlyCloseMode) {
      URL += "&recentlyCloseMode=1";
    }
    if (recentlyStartupMode || recentlyCloseMode) {
      URL += `&from_year=${year}&from_month=${month}&from_day=${day}`;
    }
    if (invoiceFlg) {
      URL += "&invoiceFlg=1";
    }
    return URL;
  }

  async function handleSearch(event) {
    const lOffset = 0;
    const lLimit = PAGE_SEARCH_LIMIT;
    const URL = searchPageDecorate(lOffset, lLimit);
    window.location.href = URL
  }

  return (
    <header id="header">
      <ScriptTag type="text/javascript" src="/jquery_js/script.js" />
      <div className="dfb header_menu">
        <a href="/">
          <img src={logo} alt="Company Search - 企業情報・法人情報検索" />
        </a>
        <div revedit="" />
        <div className="dfb menu_wrap">
          <button className="search">
            <img src={search_icon} alt="search"/>
          </button>
          <div className="hamburger" style={{display: 'none'}}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <nav className="menu" style={{display: 'none'}}>
        <ul className="dfb">
          <li><Link to="/search/">企業一覧</Link></li>
          {/* <li><a href="/">お問い合わせ</a></li> */}
        </ul>		
      </nav>
      <aside className="header_search_box" style={{display: 'none'}}>
        <div className="search_box">
          <div className="search_box_inner">
            <h2>検索条件</h2>
            <ul>
              <li>
                <p>都道府県</p>
                <div className="select_wrap">
                  <select className="pre_select" name="" value={prefCd} onChange={savePrefCd}>
                    <option value="0">全ての都道府県</option>
                    <option value="1">北海道</option>
                    <option value="2">青森県</option>
                    <option value="3">岩手県</option>
                    <option value="4">宮城県</option>
                    <option value="5">秋田県</option>
                    <option value="6">山形県</option>
                    <option value="7">福島県</option>
                    <option value="8">茨城県</option>
                    <option value="9">栃木県</option>
                    <option value="10">群馬県</option>
                    <option value="11">埼玉県</option>
                    <option value="12">千葉県</option>
                    <option value="13">東京都</option>
                    <option value="14">神奈川県</option>
                    <option value="15">新潟県</option>
                    <option value="16">富山県</option>
                    <option value="17">石川県</option>
                    <option value="18">福井県</option>
                    <option value="19">山梨県</option>
                    <option value="20">長野県</option>
                    <option value="21">岐阜県</option>
                    <option value="22">静岡県</option>
                    <option value="23">愛知県</option>
                    <option value="24">三重県</option>
                    <option value="25">滋賀県</option>
                    <option value="26">京都府</option>
                    <option value="27">大阪府</option>
                    <option value="28">兵庫県</option>
                    <option value="29">奈良県</option>
                    <option value="30">和歌山県</option>
                    <option value="31">鳥取県</option>
                    <option value="32">島根県</option>
                    <option value="33">岡山県</option>
                    <option value="34">広島県</option>
                    <option value="35">山口県</option>
                    <option value="36">徳島県</option>
                    <option value="37">香川県</option>
                    <option value="38">愛媛県</option>
                    <option value="39">高知県</option>
                    <option value="40">福岡県</option>
                    <option value="41">佐賀県</option>
                    <option value="42">長崎県</option>
                    <option value="43">熊本県</option>
                    <option value="44">大分県</option>
                    <option value="45">宮崎県</option>
                    <option value="46">鹿児島県</option>
                    <option value="47">沖縄県</option>
                    <option value="99">海外</option>
                  </select>
                </div>
              </li>
              <li>
                <p>市区町村</p>
                <TownSelectBox saveTownCd={saveTownCd} prefCd={prefCd} townCd={townCd}/>
              </li>
              <li>
                <p>営業品目</p>
                <IndustrySelectBox saveIndustryCd={saveIndustryCd} industryCd={industryCd}/>
              </li>
              <li>
                <p>資本金</p>
                <CapitalSelect saveCapitalFromCd={saveCapitalFromCd} capitalFromCd={capitalFromCd}
                                saveCapitalToCd={saveCapitalToCd} capitalToCd={capitalToCd}></CapitalSelect>
              </li>
              <li>
                <p>企業名・法人番号（13桁）</p>
                <input type="text" name="" id="" value={corpWord} onChange={saveCorpWord}/>
              </li>
              <li>
                <EstCloseDateSelect
                  recentlyStartupMode = {recentlyStartupMode}
                  recentlyCloseMode = {recentlyCloseMode}
                  year={year}
                  month={month}
                  day={day}
                  setYear={setYear}
                  setMonth={setMonth}
                  setDay={setDay}
                  setRecentlyStartupMode={setRecentlyStartupMode}
                  setRecentlyCloseMode={setRecentlyCloseMode}
                  onModeChange={enableStopCorp}
                />
              </li>
              <li className="side_check_box">
                <input type="checkbox" id="B3" name="B3" checked={includeStopCorp} onChange={saveIncludeStopCorp} disabled={diableIncludeStopCorp} />
                <label htmlFor="B3">倒産した企業を含める</label>
              </li>
              <li className="side_check_box">
                <input
                  type="checkbox"
                  id="invoice"
                  name="invoice"
                  defaultChecked={invoiceFlg}
                  onChange={toggleInvoiceFlg}
                />
                <label htmlFor="invoice">適格請求書（インボイス）<span className="inblock">発行事業者登録番号あり</span></label>
              </li>
            </ul>
            <button className="search_btn" onClick={handleSearch}>検索</button>
          </div>
          <div className="clear">
            <button className="search_close">
              <img src={ximg} alt="x" />
            </button>
          </div>
        </div>
      </aside>
    </header>
  );
}

export default Header;
