const Pagination = ( {up, count, countAll, offset, limit, handlePrevNext} ) => {
  if (count !== 0) {
    let lastCount = offset + limit;
    lastCount = lastCount > countAll ? countAll : lastCount;
    const noNextButton = offset + limit >= countAll;
    const noPrevButton = offset - limit < 0;
    const pageNaviClassName = up ? 'page_navi' : 'page_navi pn_bottom'
    return (
      <div className={pageNaviClassName}>
        {up && (
          <p className="count_number">
            {offset + 1}-{lastCount}件を表示 / 全{countAll}件
          </p>
        )}
        <div className="dfb">
          {!noPrevButton && (
            <p className="prev">
              <a data-offset={offset - limit} data-limit={limit} onClick={handlePrevNext} href="#">前のページへ</a>
            </p>
          )}
          {!noNextButton && (
            <p className="next" >
              <a data-offset={offset + limit} data-limit={limit} onClick={handlePrevNext} href="#">次のページへ</a>
            </p>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="page_navi">
      </div>
    );
  }
}
 
export default Pagination;
