import { useState, useEffect } from 'react';
import axios from 'axios';
import AdBannerH from '../parts/ad_banner_h';
import DocumentMeta from 'react-document-meta';

const CorporationSubsidy = ( {corpNo, corpName, prefName, addressName} ) => {
  const [corpCert, setCorpCert] = useState({});
  useEffect(() => {
    const URL = "https://api.company-search.jp/corp/" + corpNo + "/subsidy/";
    const fetch = async () => {
      try {
        const response = await axios.get(URL);
        const post = response.data;
        setCorpCert(post);
      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, [corpNo]);

  const resultList = [];
  if(corpCert.length > 0) {
    corpCert.map((prop, index) => {
      return resultList.push(
        <tr  className="subsidy_list" key={index}>
          <td>
            {prop.subsidyCerticertifiedDate ? prop.subsidyCerticertifiedDate : '--------'}
          </td>
          <td style={{whiteSpace: 'normal'}}>
            {prop.subsidyName ? prop.subsidyName : '--------'}
          </td>
          <td>
            {prop.subsidyAmount ? '￥' + Number(prop.subsidyAmount).toLocaleString() : '--------'}
          </td>
          <td style={{whiteSpace: 'normal'}}>
            {prop.subsidyTarget ? prop.subsidyTarget : '--------'}
          </td>
          <td>
            {prop.subsidyJointCorporateNameList ? prop.subsidyJointCorporateNameList : '--------'}
          </td>
          <td>
            {prop.subsidyFinancialResources ? prop.subsidyFinancialResources : '--------'}
          </td>
        </tr>
      )
    });
  }

  const noConnector = prefName ||  addressName ? 'の' : '';
  var title = corpName + "（" + corpNo + "）企業補助金情報 - 企業情報・法人情報検索のCompany Search";
  var desc = prefName + addressName + noConnector + corpName + "（" + corpNo + "）企業補助金情報です。Company Searchは全国約500万件の企業から様々な条件で企業を検索することが出来ます。 - 企業情報・法人情報検索のCompany Search";
  const meta = {
    title: title,
    description: desc,
    robots: "all",
    meta: {
      property: {
        'og:url': 'https://company-search.jp/corp/' + corpNo + '/subsidy/',
        'og:type': 'website',
        'og:title': title,
        'og:description': desc,
        'og:site_name': 'Company Search - 企業情報・法人情報検索',
        'og:image': 'https://company-search.jp/logo.png',
      }
    }
  };

  return (
    <div className="content subsidy_info show">
      <DocumentMeta {...meta}>
      <h2 className="ttl_style3">補助金情報</h2>
      <div className="adv">
        <AdBannerH />
      </div>
      <div class="content_inner no_pb">
        <div class="table_layout">
          <table>
            <tbody>
              <tr>
                <th scope="col">認定日</th>
                <th scope="col">補助金名</th>
                <th scope="col">補助金金額</th>
                <th scope="col">補助金の対象</th>
                <th scope="col">連名</th>
                <th scope="col">補助金財源</th>
              </tr>
              {resultList}
            </tbody>
          </table>
        </div>
      </div>
      <div className="adv">
        <AdBannerH />
      </div>
      </DocumentMeta>
    </div>
  );
}
 
export default CorporationSubsidy;
